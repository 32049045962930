<template>
  <el-row class="table">
    <span class="title">课时详情列表</span>
    <el-row class="row-bg pr-15">
      <el-col :span="24">
        <el-table
          :data="statRes"
          :stripe="true"
          :border="true"
          highlight-current-row
          empty-text="暂无符合查询条件的数据"
        >
          <el-table-column prop="className" label="班级名称" align="left"></el-table-column>

          <el-table-column prop="statDateRange" label="上课时间" align="center" width="300px"></el-table-column>

          <el-table-column prop="duration" label="课时" align="center" width="80px"></el-table-column>

          <el-table-column prop="studentAtendCount" label="出勤人数" align="center" width="100px"></el-table-column>

          <el-table-column prop="isfinished" label="排课状态" align="center" width="100px"></el-table-column>

          <el-table-column prop="role" label="课堂角色" align="center" width="100px"></el-table-column>

          <el-table-column prop="courseTitleThis" label="课程名称" align="center"></el-table-column>

          <el-table-column prop="employeeName" label="员工姓名" align="center" width="120"></el-table-column>

          <el-table-column prop="loginName" label="工号" align="center" width="120"></el-table-column>
          <el-table-column prop="content" label="上课内容" align="center"></el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="pagination.pageSizes"
          :page-size="pagination.pageSize"
          :layout="pagination.layout"
          :total="pagination.total"
        ></el-pagination>
      </el-col>
    </el-row>

    <el-row class="row-bg">
      <el-col :span="24">
        <download-excel :data="statRes" name="教师课时列表查询结果.xls">
          <el-button type="primary" size="small" style=" display: block;margin: 0 auto 10px;">导出到Excel</el-button>
        </download-excel>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import JsonExcel from 'vue-json-excel'
import { getListTeacherLesson, getListMyLesson } from '@/api/statistics'

export default {
  components: { downloadExcel: JsonExcel },
  data () {
    return {
      periodId: this.$route.query.periodId,
      courseIdSet: [this.$route.query.courseId],
      employeeIdSet: [this.$route.query.employeeId],
      role: this.$route.query.role,
      courseList: [], // 课程列表
      statRes: [], // 查询结果
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 500, 1000],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      }
    }
  },
  created () {
    this.swotchStatus()
  },
  methods: {
    swotchStatus () {
      if (this.$route.query.activeName === 'allHour') {
        this.listTeacherLessonStat()
      } else {
        this.getListMyLesson()
      }
    },
    /**
     * 教师排课统计列表
     */
    listTeacherLessonStat () {
      getListTeacherLesson({
        periodId: this.periodId,
        courseIdSet: this.courseIdSet,
        employeeIdSet: this.employeeIdSet,
        role: this.role,
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.state === 'success') {
          let statRes = res.body.list
          statRes.forEach((v, k, arr) => {
            if (v.role === 1) {
              v.role = '讲师'
            } else if (v.role === 2) {
              v.role = '助教'
            }

            if (v.isfinished === 0) {
              v.isfinished = '未上课'
            } else if (v.isfinished === 1) {
              v.isfinished = '已上课'
            } else if (v.isfinished === 2) {
              v.isfinished = '已取消'
            }
            v.duration = Math.round((v.duration / 60) * 100) / 100
            v.statDateRange =
              v.starttime.substring(0, 19) +
              ' -- ' +
              v.endtime.substring(11, 19)
          })
          this.statRes = statRes
          this.dataList = res.body.list
          this.pagination.total = res.body.total
        }
      })
    },

    getListMyLesson () {
      getListMyLesson({
        periodId: this.periodId,
        courseIdSet: this.courseIdSet,
        role: this.role,
        needCount: true,
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.state === 'success') {
          let statRes = res.body.list
          statRes.forEach((v, k, arr) => {
            if (v.role === 1) {
              v.role = '讲师'
            } else if (v.role === 2) {
              v.role = '助教'
            }

            if (v.isfinished === 0) {
              v.isfinished = '未上课'
            } else if (v.isfinished === 1) {
              v.isfinished = '已上课'
            } else if (v.isfinished === 2) {
              v.isfinished = '已取消'
            }
            v.duration = Math.round((v.duration / 60) * 100) / 100
            v.statDateRange =
              v.starttime.substring(0, 19) +
              ' -- ' +
              v.endtime.substring(11, 19)
          })
          this.statRes = statRes
          this.dataList = res.body.list
          this.pagination.total = res.body.total
        }
      })
    },

    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.swotchStatus()
    },
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.swotchStatus()
    },

    // 表头换行
    renderheader (h, { column, $index }) {
      return h('span', {}, [
        h('span', {}, column.label.split('|')[0]),
        h('br'),
        h('span', {}, column.label.split('|')[1])
      ])
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
