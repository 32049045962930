var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("课时详情列表")]),
      _c(
        "el-row",
        { staticClass: "row-bg pr-15" },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.statRes,
                    stripe: true,
                    border: true,
                    "highlight-current-row": "",
                    "empty-text": "暂无符合查询条件的数据",
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "className",
                      label: "班级名称",
                      align: "left",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "statDateRange",
                      label: "上课时间",
                      align: "center",
                      width: "300px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "duration",
                      label: "课时",
                      align: "center",
                      width: "80px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "studentAtendCount",
                      label: "出勤人数",
                      align: "center",
                      width: "100px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "isfinished",
                      label: "排课状态",
                      align: "center",
                      width: "100px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "role",
                      label: "课堂角色",
                      align: "center",
                      width: "100px",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "courseTitleThis",
                      label: "课程名称",
                      align: "center",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "employeeName",
                      label: "员工姓名",
                      align: "center",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "loginName",
                      label: "工号",
                      align: "center",
                      width: "120",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "content",
                      label: "上课内容",
                      align: "center",
                    },
                  }),
                ],
                1
              ),
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.pagination.currentPage,
                  "page-sizes": _vm.pagination.pageSizes,
                  "page-size": _vm.pagination.pageSize,
                  layout: _vm.pagination.layout,
                  total: _vm.pagination.total,
                },
                on: {
                  "size-change": _vm.handleSizeChange,
                  "current-change": _vm.handleCurrentChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-col",
            { attrs: { span: 24 } },
            [
              _c(
                "download-excel",
                {
                  attrs: {
                    data: _vm.statRes,
                    name: "教师课时列表查询结果.xls",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { display: "block", margin: "0 auto 10px" },
                      attrs: { type: "primary", size: "small" },
                    },
                    [_vm._v("导出到Excel")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }